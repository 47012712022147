import { graphql, PageProps } from "gatsby"
import React from "react"

import Layout from "src/components/Layout/Layout"
import SignIn from "src/components/Account/SignIn/SignIn"
import SEO from "src/components/seo"
import { useCheckAuth } from "src/hooks/useCheckAuth"

const Account: React.FC<PageProps> = ({ location }) => {
  useCheckAuth()

  return (
    <Layout location={location}>
      <SEO title="Login BookAble24" />
      <SignIn />
    </Layout>
  )
}

export default Account

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
